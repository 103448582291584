td.table-column-date {
    font-size: 1.2rem;
    border-bottom: 0;
}

.table-column-diary, .table-column-therapy {
    width: 50%;
}
td.table-column-diary, td.table-column-therapy {
    font-size: 1rem;
}
