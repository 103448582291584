.loading-spinner-container {
    height: 100%;
    width: 100%;
    padding: 50px;
    border-radius: 20px;
    position: absolute;
    z-index: 1000000000000000;
    display: flex;
    background-color: white;
    justify-content: center;
}

#loading-spinner {
    height: 100px !important;
    width: 100px !important;
}