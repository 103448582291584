// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";
// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
//@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Header themes
// Light
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
// Header Menu themes
// Light
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";
// Brand themes
// .brand-dark {
//   @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
// }
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}
// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
 @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
      overflow-y: auto;
      display: block;

      span {
        background: none transparent !important;
      }
  }
}

.json > pre {
  background: none #fff !important;

}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
        color: $primary !important;
    }
  }
}
.ps {padding:0 25px 0 15px !important;}
.scroll.ps > .ps__rail-y {width: 15px;}
.scroll.ps > .ps__rail-x > .ps__thumb-x {height: 13px;border-radius: 0.42rem !important;}
.scroll.ps > .ps__rail-y > .ps__thumb-y {width:13px;border-radius:0.42rem !important;}
.scroll.ps > .ps__rail-x > .ps__thumb-x:hover, .scroll.ps > .ps__rail-x > .ps__thumb-x:focus {height:13px;}
.scroll.ps > .ps__rail-y > .ps__thumb-y:hover, .scroll.ps > .ps__rail-y > .ps__thumb-y:focus {width:13px;}
.aside .aside-menu.ps > .ps__rail-y { display: none !important;}
.aside .aside-menu.ps{padding: 0 !important;}
#kt_aside_menu {padding-left:0 !important;}

// .nav-link:focus {
//     outline: none;
// }

//#tabStoricoPazienti div.VictoryContainer > svg { height: 230px !important;}
#tabStoricoPazienti .MuiDialog-paperFullScreen {background:black !important;}
#tabStoricoPazienti .PrivateTabIndicator-colorSecondary-213 {background-color: white;}
#tabStoricoPazienti .MuiTabs-indicator{background: white !important;}
// #tabStoricoPazienti rect {width:100% !important;}


// div.VictoryContainer div > div,
// div.VictoryContainer,
// #victory-clip-2 > rect,
// div.VictoryContainer svg,
// div.VictoryContainer div,
// div.VictoryContainer div svg {
//   width: 100% !important;
//   height: 70vh;
// }

/* MODIFICHE PER LAYOUT SCROLLABILE GRAFICI */
// #tabMonitoraggioStorico div.VictoryContainer > svg { height: 100vh !important;}
// #tabMonitoraggioStorico div.VictoryContainer {position: absolute !important;top: -140px;left: 20px;}



#graficoStorico div.VictoryContainer > svg { margin-left: -65px; }
// #graficoStorico clipPath rect {width: 100% !important;}
#graficoStorico svg > g:nth-child(1) {display: none;}

svg  {pointer-events: none;}

.MuiBox-root {padding: 0 !important;}

.mt-100 {margin-top: 100px;}

.li-type-monitoring-pie {
  padding-right: 20px;
  display: inline;
  border-radius: 50%;
  margin-right: 6px;
}

.MuiTab-root {font-size: 1rem !important;}
.MuiTabs-indicator {border-bottom: 3px solid white;}
.mt-120 {margin-top: 120px !important;}
//.MuiFormControl-marginNormal {margin-top: 0px !important;}
label, legend {margin-bottom: 0;}
.MuiFormControlLabel-root {margin-right: 10px !important;}
.MuiRadio-root {padding: 7px;}
.labelFarmaci {font-weight:500;font-size:0.88rem;color:var(--primary);margin-top:5px;}
.labelInterventi {font-weight:500;color:var(--primary);margin:0 3px 0 12px;}


/*CLASSI PER DASHBOARD*/
.card.card-custom > .card-body {padding:1rem !important;}

.col-md-2-5 {
  flex: 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
}

/* Linea Gialla Storico Monitoraggio */
.lineStatus {bottom:0;left:50%;position:absolute;z-index:101;height:50px;width:1px;background-color:#FCF4A3;}

.mobileHistoric .VictoryContainer {touch-action:unset !important;}



/* MODIFICHE PATIENT HISTORIC & LIVE SURVEILLANCE PER MOBILE */
.brW {border-right: 1px solid white;}

#tabMonitoraggioStorico {background-color: black; margin: 15px 0; height: calc(100vh - 88px); margin-bottom: 0;}

.mainContainerBox {background-color: black; color: white; width: 100%; height: 100%; display: flex; flex-flow: column; align-content: stretch; justify-content: space-between;}
.containerBox {border-bottom: 1px solid white; flex-grow: 1; margin: 0;}
.graphBox {display: flex; align-items: center; padding: 0;}

.angle {width: 110px; border-right: 1px solid white;}
.mainNav {top: 0px; left: -90px; position: absolute; z-index: 100;}
.mainNavLive {top: 70px; left: 120px; position: absolute; z-index: 100;font-size: 1.5rem;}
.secondaryNav {top: 10px; left: 10px; position: absolute; z-index: 100;}

.ECGbox {width: 110px;border-right: 1px solid white;display: flex;justify-content: center;flex-flow: column;}
.IMPbox {width: 110px;border-right: 1px solid white;display: flex;justify-content: center;flex-flow: column;}
.textBox {font-size: 1.3rem; font-weight: bold; text-align: center;}
.textBoxMobile {display:none;}

.colBox{ width: 110px; display: flex; flex-flow: column; justify-content: center;}
.parTitle {font-size: 1.3rem; font-weight: bold; text-align: center;}
.parLabel {color:lightblue;font-size:0.8rem;margin: 0;height: 15px;text-align: center;}
.parValue {font-size: 3rem; font-weight: bold; color: #e8244a; text-align: center;}
.parValueOnDeman {font-size: 3rem; font-weight: bold; color: lightblue; text-align: center;}
.parUM {font-size: 1rem; text-align: center}

.parBox {flex: 0 0 16.66667%;max-width: 16.66667%;}

@media (max-width: 670px) {
  .angle {display:none !important;}
  .mainNav {top: 46px !important;left: 0 !important;}
  .mainNavLive {top: 65px !important;left: 10px !important;font-size: 1.5rem;}
  .secondaryNav {top: 70px !important;left: 30px !important;}
  .ECGbox {display:none !important;}
  .IMPbox {display:none !important;}
  .textBoxMobile {display:block;position: absolute;bottom:20px;left:10px;font-size:2rem;}

  .colBox   {width: 60px !important;}
  .parTitle {font-size: 1rem !important;}
  .parValue {font-size: 2rem   !important;}
  .parUM    {font-size: 0.7rem   !important;}

  .parBox {flex:0 0 33.33333%;max-width:33.33333%;border:none !important;}

}





//======================================================================
// PATIENT LIVE SURVEILLANCE
//======================================================================

// ---------- DEFINIZIONE COLORI ---------- //
:root {
  --ecg-green:#02FC02; 
  --ecg-yellow:#FAFA04; 
  --ecg-cyan:#01FFFF; 
  --ecg-red:#FF0000; 
  --ecg-purple:#FD87FE; 
  --ecg-orange:#FF6600; 
}

// ---------- DEFINIZIONE LAYOUT ---------- //
.lmContainer {background:black;color:white;border-radius:5px;display:flex;flex-wrap:wrap;height:734px;}
.lmTopBar {flex:0 0 100%;max-width:100%;height:38px;border-bottom:2px solid white;display:flex;justify-content:space-between;}
.lmMainArea {flex:0 0 calc(100% - 375px);max-width:calc(100% - 375px);}
.lmRightArea {flex:0 0 285px;max-width:285px;border-left:2px solid white;}
.lmSideControl {flex:0 0 90px;max-width:90px;border-bottom-right-radius: 5px;background-color:#333333;background-image:linear-gradient(0deg, #000000, #333333 150px);display:flex;justify-content:center;padding:1rem 0;flex-direction:column;place-items:center;justify-content:space-evenly;}
.fullScreenMonitor .lmMainArea {height:calc(100vh - 40px);}

//COMPONENTI TOP BAR
.lmServerTime {width:100%;align-self:center;text-align:center;}
.lmWarningContainer {padding:2px 3px;}
.lmWarningDefault {width:280px;height:100%;}
.lmWarningRed {animation: blinker 1s linear infinite;width:280px;height:100%;background-color:var(--ecg-red);border-radius:5px;}
.lmWarningYellow {animation: blinker 1s linear infinite;width:280px;height:100%;background-color:var(--ecg-yellow);border-radius:5px;}
.lmTopBarActionMenu {display:flex;}
.lmTopBarActionMenu > div {border-left:2px solid white;}

// ---------- DEFINIZIONE COMPONENTI ---------- //
//ECG / RESP BOX
.lmGraphBox {width:100%;height:174px;border-bottom:2px solid white;}
.lmGraphBoxOverflow {overflow:auto hidden;}
//TASTI
.lmSideButton {box-shadow:inset 0px 1px 0px 0px #6d6a64;background:linear-gradient(to bottom, #6d6a64 5%, #373532 100%);background-color:#6d6a64;border-radius:5px;border:1px solid #000000;cursor:pointer;color:#ffffff;width:60px;height:60px;}
.lmSideButton:hover {background:linear-gradient(to bottom, #373532 5%, #6d6a64 100%);background-color:#373532;}
.lmSideButton:active {position:relative;}
//CONTENITORE PARAMETRI VITALI
.lmMeasureContainer{display:flex;width:calc(100% - 6px);margin:3px;padding:2rem 6px;height:170px;justify-content:space-between;position:relative;}
.lmMeasureCol{display:flex;flex-direction:column;justify-content:space-between;}
.lmMeasureCol2{display:flex;flex-direction:column;justify-content:space-between;width:130px;}
.lmMeasureCol3{display:flex;flex-direction:column;align-self:flex-end;width:65px;}
.lmMeasureCol4{display:flex;flex-flow:column;align-items:center;}
//CONTENITORE PARAMETRI VITALI ORIZZONTALE
.lmMeasureContainerRow{display:flex;width:277px;margin:3px;padding:2rem 6px;height:167px;justify-content:space-between;position:relative;}


//BARRE IQ
.lmIQContainer {position:relative;width:12px;height:55px;display:flex;flex-flow:column-reverse;}
.lmIQRail {background-position:center;background-size:cover;background-repeat:no-repeat;width:12px;height:55px;position:absolute;top:0;left:0;}
.lmSuccessIQFill {background-color: var(--ecg-green);width:12px;}
.lmWarningIQFill {background-color: var(--ecg-yellow);width:12px;}
.lmDangerIQFill {background-color: var(--ecg-red);width:12px;}
.lmWhiteIQFill {background-color: white;width:12px;}


//TIPOGRAFIA PARAMETRI VITALI
.lmMeasureTitle {font-size:2rem;line-height:2rem;}
.lmMeasureUnit {margin-top:10px;}
.lmMeasureParameter {font-size:6rem;line-height:4.2rem;position:absolute;bottom:26px;left:50px;}
.lmMeasureTitle2 {font-size:1.6rem;line-height:1.2rem;}
.lmMeasureParameter2 {font-size:2rem;line-height:1.4rem;}
.lmIconParameter {position:absolute;left:50px;bottom:10px;}
.lmMeasureParameterRow {font-size:5rem;line-height:6rem;position:absolute;bottom:26px;left:50px;}

//COLORI CONTENITORI
.colorGreen {color:var(--ecg-green);}
.colorYellow {color:var(--ecg-yellow);}
.colorCyan {color:var(--ecg-cyan);}
.colorRed {color:var(--ecg-red);}
.colorPurple {color:var(--ecg-purple);}
.colorOrange {color:var(--ecg-orange);}

//TABLE HR
.lmTableResponsive {overflow-y:scroll;height:100%;}
.lmTable th {position:sticky;top:0;}
.lmTable th {background:white;color:black;text-transform:uppercase;}
.lmTable thead th, .lmTable tbody tr {padding:0.5rem 0.5rem;}
.lmTable tbody {color:var(--ecg-green);}
.lmTable.table-separate th:first-child, .lmTable.table-separate td:first-child {padding-left: 0.5rem !important;}
.lmTable.table-separate tbody tr:last-child td {border-bottom:1px solid white !important;}

//ALERT PARAMETRI VITALI
.lmBorderWARNING {border:2px solid var(--ecg-yellow);border-radius:5px;}
.lmBorderDANGER {border:2px solid var(--ecg-red);border-radius:5px;}

//ICONA TEMPERATURA ARANCIONE
.fillOrange path {fill:var(--ecg-orange);}

//COMPONENTE SELECT
.select{position:relative;display:inline-block;height:40px;width:300px;}
.select select{font-family:'Arial';display:inline-block;width:100%;cursor:pointer;padding:6px 40px 9px 10px;outline:0;border-right:1px solid #fff;border-radius:0;background:#000;color:#fff;appearance:none;-webkit-appearance:none;-moz-appearance:none;border-top-left-radius:5px;}
.fullScreenMonitor .select select{border-top-left-radius:0px;}
.select select::-ms-expand{display:none}
.select select:focus,.select select:hover{color:#fff;background:#000}
.select select:disabled{opacity:.5;pointer-events:none}
.select-arrow{position:absolute;top:10px;right:15px;width:0;height:0;border:solid #fff;border-width:0 3px 3px 0;display:inline-block;padding:3px;transform:rotate(45deg);-webkit-transform:rotate(45deg)}
.select select:focus~.select-arrow,.select select:hover~.select-arrow{border-color:#e8e8e8}
.select select:disabled~.select-arrow{border-top-color:#ccc}

//MANTENGO BORDO ROTONDO SU ULTIMO TASTO (RESIZE)
.lmTopBarActionMenu .barNavTime:last-child() {border-top-right-radius: 5px;}
.fullScreenMonitor .lmTopBarActionMenu .barNavTime:last-child() {border-top-right-radius: 0px;}

//IMPOSTO IL FULLSCREEN
.fullScreenMonitor .lmGraphBox {height:25%;}
.fullScreenMonitor .lmMeasureContainer {height:calc(25% - 5px);}

@keyframes blinker {50% {opacity: 0;}}
// ---------- SPECIFICHE MOBILE DEVICE ---------- //
@media (max-width: 670px) {
  //Fix che nasconde le sidebar laterali
  .lmContainer{overflow:auto;}

  

}



// ---------- REGOLE MOBILE  ---------- //
//STATI WARNING E DANGER
.lmGeneralStatusDANGERMobile {background-color:var(--ecg-red);width:100%;height:29px;border-radius:5px;}
.lmGeneralStatusWARNINGMobile {background-color:var(--ecg-yellow);width:100%;height:29px;border-radius:5px;}


.lmBorderDANGERMobile {border:3px solid var(--ecg-red);border-radius:5px;margin:3px;}
.lmBorderWARNINGMobile {border:3px solid var(--ecg-yellow);border-radius:5px;margin:3px;}



//CONTENITORE RIGA PARAMETRI
.lmMeasureHRMobile {display:flex;width:100%;padding:5px 3px 5px 3px;justify-content:space-between;position:relative;height:66px;font-size:0.8rem;}
.lmMeasureContainerMobile {display:flex;width:100%;padding:6px 3px 0px 3px;justify-content:space-between;position:relative;height:74px;font-size:0.8rem;}

//OGGETTI RIGA 1 HR
.lmMeasureColMobile {display:flex;flex-direction:column;}
.lmQIColMobile {display:flex;align-items:center;}
.lmColHR {width:130px;}

.lmMeasureTitle1Mobile {font-size:1.6rem;line-height:1.6rem;}
.lmMeasureTitle2Mobile {font-size:1.4em;line-height:1.4rem;}

.lmMeasureParameterMobile {font-size:3.6rem;line-height:3rem;display:flex;justify-content: space-between;}

.hrContainerIconMobile {position:relative;}
.hrIconMobile {position:absolute;right:-5px;bottom:-10px;}

.lmPar3Cols {display:flex;width:32%;padding:0.5rem;justify-content:space-between;}
.lmPar2Cols {display:flex;width:46%;padding:0.5rem;justify-content:space-between;}

//======================================================================
// FINE PATIENT LIVE SURVEILLANCE
//======================================================================










//======================================================================
// PATIENT HISTORIC ECG
//======================================================================

// ---------- DEFINIZIONE COLORI ---------- //
:root {
  --ecg-green:#02FC02; 
  --ecg-yellow:#FAFA04; 
  --ecg-cyan:#01FFFF; 
  --ecg-red:#FF0000; 
  --ecg-purple:#FD87FE; 
  --ecg-orange:#FF6600; 
}

// ---------- DEFINIZIONE LAYOUT ---------- //
.hmContainer {background:black;color:white;border-radius:5px;display:flex;flex-wrap:wrap;}
.hmTopBar {flex:0 0 100%;max-width:100%;height:38px;border-bottom:2px solid white;display:flex;justify-content:space-between;}
.hmMainArea {flex:0 0 calc(100% - 375px);max-width:calc(100% - 375px);}
.hmRightArea {flex:0 0 285px;max-width:285px;border-left:2px solid white;}
.hmSideControl {flex:0 0 90px;max-width:90px;border-bottom-right-radius: 5px;background-color:#333333;background-image:linear-gradient(0deg, #000000, #333333 150px);display:flex;justify-content:center;padding:1rem 0;flex-direction:column;place-items:center;justify-content:space-evenly;}
.fullScreenMonitor .hmMainArea {height:calc(100vh - 40px);}

//COMPONENTI TOP BAR
.hmServerTime {width:100%;align-self:center;text-align:center;display: flex;justify-content: space-between;}
.hmWarningContainer {padding:2px 3px;}
.hmWarningDefault {width:280px;height:100%;}
.hmWarningRed {animation: blinker 1s linear infinite;width:280px;height:100%;background-color:var(--ecg-red);border-radius:5px;}
.hmWarningYellow {animation: blinker 1s linear infinite;width:280px;height:100%;background-color:var(--ecg-yellow);border-radius:5px;}
.hmTopBarActionMenu {display:flex;}
.hmTopBarActionMenu > div {border-left:2px solid white;}

// ---------- DEFINIZIONE COMPONENTI ---------- //
//ECG / RESP BOX
.hmGraphBox {width:100%;height:174px;border-bottom:2px solid white;position:relative;}
.hmGraphBoxOverflow {overflow:auto hidden;}
//TASTI
.hmSideButton {box-shadow:inset 0px 1px 0px 0px #6d6a64;background:linear-gradient(to bottom, #6d6a64 5%, #373532 100%);background-color:#6d6a64;border-radius:5px;border:1px solid #000000;cursor:pointer;color:#ffffff;width:60px;height:60px;}
.hmSideButton:hover {background:linear-gradient(to bottom, #373532 5%, #6d6a64 100%);background-color:#373532;}
.hmSideButton:active {position:relative;}
//CONTENITORE PARAMETRI VITALI
.hmMeasureContainer{display:flex;width:calc(100% - 6px);margin:3px;padding:2rem 6px;height:170px;justify-content:space-between;position:relative;}
.hmMeasureCol{display:flex;flex-direction:column;justify-content:space-between;}
.hmMeasureCol2{display:flex;flex-direction:column;justify-content:space-between;width:130px;}
.hmMeasureCol3{display:flex;flex-direction:column;align-self:flex-end;width:65px;}
.hmMeasureCol4{display:flex;flex-flow:column;align-items:center;}
//CONTENITORE PARAMETRI VITALI ORIZZONTALE
.hmMeasureContainerRow{display:flex;width:277px;margin:3px;padding:2rem 6px;height:167px;justify-content:space-between;position:relative;}


//BARRE IQ
.hmIQContainer {position:relative;width:12px;height:55px;display:flex;flex-flow:column-reverse;}
.hmIQRail {background-position:center;background-size:cover;background-repeat:no-repeat;width:12px;height:55px;position:absolute;top:0;left:0;}
.hmSuccessIQFill {background-color: var(--ecg-green);width:12px;}
.hmWarningIQFill {background-color: var(--ecg-yellow);width:12px;}
.hmDangerIQFill {background-color: var(--ecg-red);width:12px;}
.hmWhiteIQFill {background-color: white;width:12px;}


//TIPOGRAFIA PARAMETRI VITALI
.hmMeasureTitle {font-size:2rem;line-height:2rem;}
.hmMeasureUnit {margin-top:10px;}
.hmMeasureParameter {font-size:6rem;line-height:4.2rem;position:absolute;bottom:26px;left:50px;}
.hmMeasureTitle2 {font-size:1.6rem;line-height:1.2rem;}
.hmMeasureParameter2 {font-size:2rem;line-height:1.4rem;}
.hmIconParameter {position:absolute;left:50px;bottom:10px;}
.hmMeasureParameterRow {font-size:5rem;line-height:6rem;position:absolute;bottom:26px;left:50px;}

//COLORI CONTENITORI
.colorGreen {color:var(--ecg-green);}
.colorYellow {color:var(--ecg-yellow);}
.colorCyan {color:var(--ecg-cyan);}
.colorRed {color:var(--ecg-red);}
.colorPurple {color:var(--ecg-purple);}
.colorOrange {color:var(--ecg-orange);}

//TABLE HR
.hmTableResponsive {overflow-y:scroll;height:100%;}
.hmTable th {position:sticky;top:0;}
.hmTable th {background:white;color:black;text-transform:uppercase;}
.hmTable thead th, .hmTable tbody tr {padding:0.5rem 0.5rem;}
.hmTable tbody {color:var(--ecg-green);}
.hmTable.table-separate th:first-child, .hmTable.table-separate td:first-child {padding-left: 0.5rem !important;}
.hmTable.table-separate tbody tr:last-child td {border-bottom:1px solid white !important;}

//ALERT PARAMETRI VITALI
.hmBorderWARNING {border:2px solid var(--ecg-yellow);border-radius:5px;}
.hmBorderDANGER {border:2px solid var(--ecg-red);border-radius:5px;}

//ICONA TEMPERATURA ARANCIONE
.fillOrange path {fill:var(--ecg-orange);}

//COMPONENTE SELECT
.select{position:relative;display:inline-block;height:40px;width:300px;}
.select select{font-family:'Arial';display:inline-block;width:100%;cursor:pointer;padding:6px 40px 9px 10px;outline:0;border-right:1px solid #fff;border-radius:0;background:#000;color:#fff;appearance:none;-webkit-appearance:none;-moz-appearance:none;border-top-left-radius:5px;}
.fullScreenMonitor .select select{border-top-left-radius:0px;}
.select select::-ms-expand{display:none}
.select select:focus,.select select:hover{color:#fff;background:#000}
.select select:disabled{opacity:.5;pointer-events:none}
.select-arrow{position:absolute;top:10px;right:15px;width:0;height:0;border:solid #fff;border-width:0 3px 3px 0;display:inline-block;padding:3px;transform:rotate(45deg);-webkit-transform:rotate(45deg)}
.select select:focus~.select-arrow,.select select:hover~.select-arrow{border-color:#e8e8e8}
.select select:disabled~.select-arrow{border-top-color:#ccc}

//MANTENGO BORDO ROTONDO SU ULTIMO TASTO (RESIZE)
.hmTopBarActionMenu .barNavTime:last-child() {border-top-right-radius: 5px;}
.fullScreenMonitor .hmTopBarActionMenu .barNavTime:last-child() {border-top-right-radius: 0px;}

//IMPOSTO IL FULLSCREEN
.fullScreenMonitor .hmGraphBox {height:25%;}
.fullScreenMonitor .hmMeasureContainer {height:calc(25% - 5px);}

@keyframes blinker {50% {opacity: 0;}}
// ---------- SPECIFICHE MOBILE DEVICE ---------- //
@media (max-width: 670px) {
  //Fix che nasconde le sidebar laterali
  .hmContainer{overflow:auto;}

  

}
//======================================================================
// FINE PATIENT HISTORIC ECG
//======================================================================






















//OVERRIDE COLORE BADGE ERROR
.MuiBadge-colorError {
  color: #fff;
  background-color: #F01900 !important;
}























/* VOIP */
.voipModal {width: 320px;}
.voipContainer {display: flex;flex-flow:column;}
.voipMedia {background:#333;width:272px;height:220px;}
.voipMedia .btn .svg-icon, .voipDetail .btn .svg-icon {margin-right:0;fill:white;}
.voipActions {background:#333;padding-bottom:10px;padding:10px 0;}
.symbol.symbol-38 .symbol-label {width: 38px;height: 38px;}
.tooltip {z-index:1400 !important;}
.voipMinimizeActions svg {fill:white;}



/*Monitoraggio Storico*/
.hmContainer {border-radius:5px;margin:0;background:black;color:white;}
.fullScreenMonitor {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999999;
  border-radius: 0;
}
.hmTopBar {display:flex;justify-content:space-between;width:100%;height:40px;border-bottom:2px solid white;}
.hmTopBarPatient {height:40px;border-bottom:2px solid white;padding:5px;line-height:13px;}

/*Barra superiore delle funzioni*/
.barContainer {display:flex;justify-content:space-between;}
.barContainer div {font-weight:500;line-height:0.8rem;}
.barContainer > div > div {padding:5px;line-height:2rem;align-items:center;height:100%;border-right:2px solid white;}
/*bordi barra delle funzioni*/
.fixBDesktop {border-right:2px solid white !important;}
.fixBorder {border-left:2px solid white !important;}

.barContainer > div > div:last-child {border-right:none;}
.barContainer > div > div:first-child {border-left:2px solid white;}

/*Intervallo di date*/
.barDate {display:flex;}
.barDate > div {display:flex;flex-direction:column;align-items:center;padding:0 5px;line-height:1.2rem;}
.barDate > div > span:first-child {color:#FCF4A3;}
.barDate > div > span:last-child {font-size:0.8rem;}
/*Pulsanti*/
.barNavTime {display:flex;justify-content:center;width:30px;}
.barNavTime:hover, .barNavTimeSelected {background:#4a4a4a;color:#FFFFFF;cursor:pointer;}

.barMonitoredLabel {color:#FCF4A3;font-weight:600;font-size:1.2rem;margin-left:4px;}

/*strip di grafici*/
.strip {padding:0;}
.strip > div {display:flex;border-bottom:2px solid white;margin:0;width:100%;height:100%;}
.strip:last-child > div {border:none;}
.strip > div > div:first-child {display:flex;flex-direction:column;justify-content:center;align-items:center;width:9%;margin-right:10px;border-right:2px solid white;}
.stripColIcon {margin-bottom:15px;}
.strip > div > div > div:nth-of-type(2) {font-size:1.7rem;font-weight:600;}
.strip > div > div > div:nth-of-type(3) {font-size:0.9rem;margin-top:-8px;}

//VERIFICARE MOBILE
@media (max-width: 768px) {
  .hmTopBar {height:80px;flex-direction:column;border:none;}
  .barContainer {height:40px;border-bottom:2px solid white;}
  .barContainer > div:first-child, .barContainer > div:last-child {border:none !important;}
  .strip > div > div:first-child {width:18%;}
  .stripColIcon {margin:15px;}
  // .barContainer {display:block;justify-content:unset;margin:0 0.25rem !important;}
  // .barContainer > div {flex: 0 0 100%;max-width: 100%;border-right:none;border-top:0.25rem solid white;}
  .fixBDesktop {border-right:none !important;}
  .fixBorder {border-left:none !important;}

}
@media (max-width: 576px) {
  .strip > div > div:first-child {width:25%;min-width:25%;}
}

@media (min-width: 768px) {
  .col-md-2-5 {
    flex: 20%;
    max-width: 20%;
    position: relative;
    padding-right: 12.5px;
    padding-left: 12.5px;
  }
}
@media (min-width: 992px) {
  .header-fixed.subheader-fixed .subheader {
    box-shadow: 0px 5px 5px 0px rgba(82, 63, 105, 0.08);
  }
}

.content {padding: 20px 0 !important;}
.aside {box-shadow: 0px 0px 10px 0px rgba(82, 63, 105, 0.08);}
.col-md-60 {
  flex: 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
}
@media (min-width: 768px) {
  .col-md-60 {
    flex: 60%;
    max-width: 60%;
    position: relative;
    padding-right: 12.5px;
    padding-left: 12.5px;
  }
}

.col-md-40 {
  flex: 100%;
  max-width: 100%;
  position: relative;
  width: 100%;
  padding-right: 12.5px;
  padding-left: 12.5px;
}
@media (min-width: 768px) {
  .col-md-40 {
    flex: 40%;
    max-width: 40%;
    position: relative;
    padding-right: 12.5px;
    padding-left: 12.5px;
  }
}

.blink {
  animation: blinker 1s linear infinite;
}

.cursor-pointer {
  cursor: grab;
}

.cursor-grab {
  height: auto !important;
  cursor: grab;
}

.cursor-grabbing {
  height: auto !important;
  cursor: grabbing;
}
