.urcency-button-container {
    display: flex;
    justify-content: space-around;
    align-content: center;
    margin: 10px;
}

.urgceny-success.MuiToggleButton-root.Mui-selected {
    color: white;
    background-color: #1BC5BD !important;
}

.urgceny-warning.MuiToggleButton-root.Mui-selected {
    color: white;
    background-color: #FFA800 !important;
}

.urgceny-danger.MuiToggleButton-root.Mui-selected {
    color: white;
    background-color: #F64E60 !important;
}